





















 import { Page, Sort, Filter, Edit, Toolbar } from "@syncfusion/ej2-vue-grids";
import { Component, Vue } from 'vue-property-decorator'
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import {MyGridOption} from '@/Helper/MyDataAdapter';
Vue.use(GridPlugin);
@Component({
     components: {
  },
  provide:{
     grid: [Page, Sort, Filter, Edit, Toolbar]
  }
})
export default class Student extends Vue {
  dataManager = MyGridOption.getDataManager('PartnerStudentList');
  filterOptions = {    type: 'Excel'};
  wrapSettings= { wrapMode: 'Both ' };
}
